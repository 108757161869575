:root {
  --border-radius: 24px;
  --color-bg: #232336;
  --color-fg: #f6e1e1;
  --color-primary: #eb4d55;
  --color-secondary: #ff9d76;
  --color-secondary-dark: #af5d36;
  --pattern-bg: repeating-linear-gradient(
    45deg,
    var(--color-primary),
    var(--color-primary) 4px,
    transparent 4px,
    transparent 8px
  );
  --initial-width: 64px;
  --font-default: 'Open Sans', Arial, Helvetica, sans-serif;
}

/* light */
/* :root {
  --color-bg: #fffdfd;
  --color-fg: #262643;
  --color-primary: #ab0d15;
  --color-secondary: #552007;
  --color-secondary-dark: #af5d36;
} */

html,
body {
  margin: 0;
}

body {
  font-family: var(--font-default);
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg);
  color: var(--color-fg);
  padding: 16px 48px;
  font-size: 16px;
}

h1 {
  font-size: 30px;
  color: var(--color-primary);
  line-height: 1.5;
  margin-bottom: 0.2em;
}

h2 {
  font-size: 2em;
  color: var(--color-secondary);
  font-weight: 300;
}

h3 {
  font-size: 1.6em;
  font-weight: 400;
}

h4 {
  font-size: 1.3em;
  font-weight: 600;
  color: var(--color-secondary);
}

img {
  border-top-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

p {
  line-height: 1.7;
}

a {
  color: var(--color-secondary);
  transition: 0.2s ease color;
  letter-spacing: 0.05em;
}
a:hover {
  color: var(--color-secondary-dark);
}

button {
  border: none;
  outline: none;
  border-radius: 24px;
  padding: 11px 18px;
  color: var(--color-bg);
  background-color: var(--color-fg);
}

a > button {
  text-decoration: none;
  color: var(--color-bg);
  cursor: pointer;
}

footer {
  margin: auto;
  text-align: center;
  opacity: 0.6;
  font-size: 0.85em;
  line-height: 2;
}

.cols-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 64px;
}

.intro-grid {
  display: grid;
  grid-template-areas: 'title contact' 'initialism initialism' 'details details' 'image image';
  grid-template-columns: auto 1fr;
  gap: 8px 32px;
  max-width: 100%;
}
.g-title {
  grid-area: title;
}
.g-initialism {
  grid-area: initialism;
  justify-self: center;
}
.g-details {
  grid-area: details;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 64px;
  justify-self: center;
  align-self: center;
}
.g-image {
  grid-area: image;
  align-self: start;
  display: flex;
  align-items: center;
  justify-content: center;
}
.g-contact {
  grid-area: contact;
  align-self: center;
  justify-self: end;
}

@media (max-width: 1080px) {
  .cols-3 {
    grid-template-columns: 1fr;
  }

  .intro-grid {
    grid-template-areas: 'title contact' 'initialism initialism' 'details details' 'image image';
    grid-template-columns: 1fr;
    gap: 24px;
  }
}

@media (max-width: 640px) {
  .intro-grid {
    grid-template-areas: 'title' 'contact' 'initialism' 'details' 'image';
  }
}

.image-wrapper {
  position: relative;
  width: 100%;
  max-width: 800px;
}
.image-wrapper > img {
  width: 100%;
  height: auto;
}
.image-wrapper::before {
  content: '';
  position: absolute;
  right: -5%;
  bottom: -5%;
  width: 50%;
  height: 50%;
  border-bottom-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  z-index: -1;
  background: var(--pattern-bg);
}

.display-font {
  font-family: 'Monoton', 'Montserrat', Georgia, 'Times New Roman', Times, serif;
  font-weight: 400;
  color: var(--color-primary);
}

.contact {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: auto 1fr;
  gap: 16px;
  align-items: center;
  margin-top: 16px;
  justify-items: end;
}

.bottom-contact {
  margin: auto;
  margin-bottom: 64px;
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 32px;
}

.initialism {
}
.initial {
  display: inline-block;
}
.initial::first-letter {
  font-family: 'Monoton', 'Montserrat', Georgia, 'Times New Roman', Times, serif;
  font-size: 1.25em;
  margin-right: 8px;
  color: var(--color-primary);
}

.nowrap {
  word-wrap: normal;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.content {
  padding-top: 10vmin;
  padding-bottom: 20vmin;
}

.point {
  position: relative;
  display: block;
  background: var(--color-bg);
  border-top-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}
/* .point::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--color-bg);
  border-top-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  opacity: 1;
  z-index: -1;
} */
.point::after {
  content: '';
  width: 80%;
  height: 80%;
  position: absolute;
  bottom: -5%;
  right: -5%;
  background: var(--color-secondary);
  border-top-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  opacity: 0.05;
  z-index: -2;
}

@keyframes fade-in {
  from {
    opacity: 0;
    top: 20px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
}

.fade-out {
  opacity: 0;
  position: relative;
  top: 40px;
}
.fade-in {
  animation: 1s ease-out 0.25s fade-in;
  animation-fill-mode: forwards;
}
